.pill {
  @include smallcaps;
  color: white;
  background-color: $gray;
  border-radius: 3px;
  padding: 3px 6px;
  text-align: center;
  line-height: 1.4;

  &.active {
    background-color: $primary;
  }

  &.paused {
    background-color: #e0c72f;
  }

  &.activating, &.scheduled {
    background-color: #4e89da;
  }

  &.deleted, &.archived {
    background-color: #E03D35;
  }

  &.theme {
    background-color: $brown-lighter;
  }

  &.light-theme {
    background-color: $beige;
    color: $brown;

    &.hyperlink {
      &:hover {
        background-color: scale-color($beige, $lightness: -10%);
      }

      &:active {
        background-color: scale-color($beige, $lightness: -5%);
      }
    }
  }

  &.natural-case {
    text-transform: none;
  }

  &.in-collection-nowrap {
    margin: 0 5px 0 0;
    white-space: nowrap;
  }

  // add margin-bottom if pills will stack amongst each other
  &.in-collection-wrap {
    margin: 0 5px 5px 0;
  }

  &.more-padding {
    padding: 6px 12px;
  }

  &.larger {
    font-size: 13px;
  }
}

.campaign-pill {
  display: inline-block;

  &.campaign-active {
    background-color: #E6E6F2;
    color: #4A4BFF;
  }

  &.campaign-inactive {
    background-color: #E6E6F2;
    color: #4A4BFF;
    opacity: 0.7;
  }

  &.campaign-issue {
    background-color: #FFE0E0;
    color: #D30001;
  }

  &.campaign-posted {
    background-color: #CDFFCD;
    color: #007F01;
  }

  &.campaign-waiting {
    background-color: #FFECCC;
    color: #965E02;
  }

  &.influencer-table-header-badge {
    @include smallcaps;
    font-size: 13px;
  }

  &.no-background {
    background-color: transparent;
  }
}

.email-group-pill {
  display: inline-block;

  &.email-group-created {
    background-color: #E6E6F2;
    color: #4A4BFF;
  }

  &.email-group-scheduled {
    background-color: #FFECCC;
    color: #965E02;
  }

  &.email-group-run {
    background-color: #CDFFCD;
    color: #007F01;
  }
}